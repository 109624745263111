/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O nás"} description={"Chalupa nabízí ubytování (nejen) pro rodiny s dětmi, na víkendové nebo letní pobyty. Dále podkrovní sál na jógu či jiné sebe~realizační akce."} seoTitle={"O nás - Chalupa Ořešák u Kouřimi"} ogTitle={"O nás - Chalupa Ořešák u Kouřimi"} ogDescription={"Chalupa nabízí ubytování (nejen) pro rodiny s dětmi, na víkendové nebo letní pobyty. Dále podkrovní sál na jógu či jiné sebe~realizační akce."}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-qldpnf --parallax pt--80" style={{"marginBottom":0,"paddingBottom":177}} name={"[[UNIsecname1]]-0"} layout={"l13"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/38340/07a18969ef7144978585e9675addd50c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/38340/07a18969ef7144978585e9675addd50c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/38340/07a18969ef7144978585e9675addd50c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/38340/07a18969ef7144978585e9675addd50c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/38340/07a18969ef7144978585e9675addd50c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/38340/07a18969ef7144978585e9675addd50c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/38340/07a18969ef7144978585e9675addd50c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/38340/07a18969ef7144978585e9675addd50c_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--1 flex--center" anim={"2"} animS={"2"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}} animS={"3"}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">O nás</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0}} name={"[[UNIgenericsecname2]]"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1 flex--center" style={{"maxWidth":""}} anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":3,"paddingBottom":0}} name={"u5hed1xttet"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"paddingTop":25,"marginTop":0}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":609}} content={"Vítáme Vás na naší chaloupce. V domku ze 30. let minulého století, zrekonstruovaného a zútulněného <br>s pečlivým přihlédnutím\nk původním <br>a přírodním materiálům a technologiím. <br>~<br>Čeká vás atmosféra starodávného stavení, které poskytuje pohodlné útočiště.\n<br>~<br>V kontaktu s původním člověk zůstává\nprostřednictvím zachovalých detailů,\nale i větších celků.\n<br>Původní jsou dřevěné podlahy, překládaný strop,\n<br>dveře i špaletová okna.\nNa starobylost stavby upozorňuje také starý zrenovovaný nábytek."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":477}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/78e386e572344fb88a9145eaf3ca7d72_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/78e386e572344fb88a9145eaf3ca7d72_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/78e386e572344fb88a9145eaf3ca7d72_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/78e386e572344fb88a9145eaf3ca7d72_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/78e386e572344fb88a9145eaf3ca7d72_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":609}} content={"Můžete si u nás zkusit vykouzlit\ndobroty vařením na plotně a nebo pečením\nv troubě v kachlových kamnech.\nPosezení u kamen, ze kterých sálá teplo <br>a praská\nv nich dřevo, je věc více než příjemná.\n<br>~<br>V případě deštivého počasí můžete nahlédnout <br>do naší vybavené knihovny a začíst se do mnoha žánrů, kterými disponuje. Najdete zde také k zapůjčení různé deskové a společenské hry. <br>Ovšem TV u nás nehledejte, u nás preferujeme pospolitost a společně prožitý čas. <br>Mimochodem, kdy jste naposledy obuli holinky, oblékli pláštěnku a byli se projít v dešti? :)"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"paddingTop":2}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":609}} content={"<br>Majitelkou Martou je trpělivě renovováno\n<br>a vytvářeno stylové zázemí, které podtrhuje <br>ducha starého stavení.\nA to nejen s dostatkem <br>prostor pro\npřespávání a přebývání.\n<br>~<br>Jistě uvítáte i blahodárný klid, který\n<br>polosamota poskytuje.<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":503}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/f563d36c923840e58cb1985c942aaac6_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/f563d36c923840e58cb1985c942aaac6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/f563d36c923840e58cb1985c942aaac6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/f563d36c923840e58cb1985c942aaac6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/f563d36c923840e58cb1985c942aaac6_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":609}} content={"Marta je světoběžnice, která se po letech strávených na cestách usadila a splnila si tak svůj sen <br>o \"babiččině chaloupce\". Tu pečlivě zrekonstruovala <br>do té podoby, že se v ní budete cítit, jako když máte <br>na chvíli možnost zastavit čas.. <br>~<br>Polosamota, absolutní klid, nepřetržitý vábivý zpěv ptáků, bujná zeleň, staré majestnátné stromy <br>a klidná hladina rybníka, za který zapadá slunce...<br>to vše vám dobije baterky na dlouho dopředu. <br>Věřím, že se k nám budete rádi vracet :)<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":4,"paddingBottom":0,"marginBottom":0}} layout={"l8"} name={"7plqtdc1r9x"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"marginTop":35}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/651f1e196fae4ede992f68c5ae78c890_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/651f1e196fae4ede992f68c5ae78c890_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/651f1e196fae4ede992f68c5ae78c890_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/651f1e196fae4ede992f68c5ae78c890_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/651f1e196fae4ede992f68c5ae78c890_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"paddingBottom":0,"marginBottom":0}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/114b3b9b543d473591e2410aea1cac29_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/114b3b9b543d473591e2410aea1cac29_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/114b3b9b543d473591e2410aea1cac29_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/114b3b9b543d473591e2410aea1cac29_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/114b3b9b543d473591e2410aea1cac29_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/98957f79793d4d9ab296e5d947072440_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/98957f79793d4d9ab296e5d947072440_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/98957f79793d4d9ab296e5d947072440_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/98957f79793d4d9ab296e5d947072440_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/98957f79793d4d9ab296e5d947072440_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/df798d8a0f8e49888c287571a88d9fd8_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/df798d8a0f8e49888c287571a88d9fd8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/df798d8a0f8e49888c287571a88d9fd8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/df798d8a0f8e49888c287571a88d9fd8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/df798d8a0f8e49888c287571a88d9fd8_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/8cd054f1de1d4fdfac7594355aff1bee_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/8cd054f1de1d4fdfac7594355aff1bee_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/8cd054f1de1d4fdfac7594355aff1bee_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/8cd054f1de1d4fdfac7594355aff1bee_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/8cd054f1de1d4fdfac7594355aff1bee_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/c87567b1044a4b009d9ca7d8b203dc68_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38340/c87567b1044a4b009d9ca7d8b203dc68_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/c87567b1044a4b009d9ca7d8b203dc68_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/c87567b1044a4b009d9ca7d8b203dc68_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/c87567b1044a4b009d9ca7d8b203dc68_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-z4x1f3 --parallax" style={{"paddingTop":0,"marginBottom":0,"paddingBottom":0}} name={"[[UNIsectionname3]]"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/c570035c6a20481fa5c414230c0af1eb_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"marginBottom":0,"paddingBottom":142,"marginTop":49}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2 pb--16 pt--16" style={{"marginBottom":175}} anim={"7"} animS={"2"}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40" style={{"backgroundColor":"var(--color-supplementary)","marginTop":0,"paddingTop":0}} name={"[[UNIsecname7]]"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":"","paddingTop":0}} anim={""} animS={"2"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">~ Přijeďte si k nám jen tak (po)být...<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":205}}>
            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">Marta Nováková<br>Nová Ves III 44 <br>Svojšice okr. Kolín 280 02<br>+420 608 859 625<br>chalupa.oresak@gmail.com<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}